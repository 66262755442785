import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Captcha from "./Captcha";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../utils/urls";
import axios from "axios";
import { InputMask } from "primereact/inputmask";
import * as alertify from "alertifyjs";
import { useTranslation } from "react-i18next";
import { Cookies } from 'react-cookie';


const Form = () => {
  const { title_for_id, title  } = useParams();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get('i18next')

  console.log(title, title_for_id, "333333333333333333333333333");

  const [titleText, setTitleText] = useState(title_for_id);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [bthDate, setBthDate] = useState("");
  const [pnfl, setPnfl] = useState("");
  const [university, setUniversity] = useState("");
  const [trainingDirection, setTrainingDirection] = useState("");
  const [yearGraduation, setYearGraduation] = useState("");
  const [placeWork, setPlaceWork] = useState("");
  const [address, setAddress] = useState("");
  const [skills, setSkills] = useState("");
  const [comment, setComment] = useState("");
  const [checkbox, setCheckbox] = useState("");
  const [file, setFile] = useState("");
  const [validPnfl, setValidatePnfl] = useState("");
  const [success, setSuccess] = useState(false);
  const [filesize, setFileSize] = useState(null);
  const [fileformat, setFileFormat] = useState("");
  const [vacancy, setVacancy] = useState([]);

  const navigate = useNavigate();

  function* range(start, end, step) {
    while (start < end) {
      yield start;
      start += step;
    }
  }
  const date = new Date();
  let rangeYear = Array.from(range(1970, date.getFullYear() + 1, 1));

  useEffect(() => {
    if (pnfl.length === 14 && pnfl.match(/^[0-9]+$/) != null) {
      axios
        .post(`${BASE_URL}/check-employee/`, { pnfl, bthDate })
        .then((response) => {
          setValidatePnfl(response.data['message']);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [pnfl])


  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("titleText", titleText);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("bthDate", bthDate);
    formData.append("pnfl", pnfl);
    formData.append("university", university);
    formData.append("trainingDirection", trainingDirection);
    formData.append("yearGraduation", yearGraduation);
    formData.append("placeWork", placeWork);
    formData.append("address", address);
    formData.append("skills", skills);
    formData.append("comment", comment);
    formData.append("checkbox", checkbox);
    formData.append("file", file);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const result = window.confirm(t("Barcha ma'lumotlarni tasdiqlaysizmi?"));

    if (file == null) {
      alertify.alert(t("Resume yuborish majburiy!!!"));
      return;
    }
    if (!checkbox) {
      alertify.alert(t("Shartlarga rozilik bildiring"));
      return;
    }


    if (validPnfl && validPnfl !== "" || pnfl.length !== 14) {
      alertify.alert(t("Shaxsiy ma'lumotlar bo'yicha xatolik bor"));
      return;
    }

    if (result) {
      axios
        .post(`${BASE_URL}/add-employee/`, formData, config)
        .then((response) => {
          alertify.alert(t("Ma'lumotlaringiz o'rganib chiqish va qayta ishlash uchun qabul qilindi. Natijasi yuzasidan Sizning elektron adresingizga qo'shimcha ma'lumot yuboriladi."));
          navigate("/");
        })
        .catch((error) => {
          alertify.alert(t("Xatolik bor. Zarur ma'lumotlar to'liq emas!"));
        });
    }
  };


  useEffect(() => {
    axios
      .get(`${BASE_URL}/vacancy/${lang}/${title}`)
      .then((response) => {
        setVacancy(response.data);
        setTitleText(response.data.title)
 
      })
      .catch((err) => console.log(err));
  }, [lang]);
  return (
    <div className="my-6 flex items-center justify-center">
      <div className=" 2xl:w-[50%]  md:w-[70%] w-[90%] rounded-lg shadow bg-white pb-7 px-8 ">
        <h1 className="text-center md:text-3xl text-xl py-2 mt-5">
          {t("Quyidagi shaklni toʻldiring.")}
        </h1>
        <p className="text-center md:text-xl text-md py-5">
          {t("Siz murojaat qilyapsiz:")} {titleText}
        </p>

        <form onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-4 group">
              <label
                for="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("Ism")} <code className="text-red-600">*</code>
              </label>
              <input
                type="text"
                id="name"
                onChange={(e) => setFirstName(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                for="lastname"
                className="block mb-2 text-sm font-medium text-gray-900"
              >{t("Familiya")}
                <code className="text-red-600">*</code>
              </label>
              <input
                type="text"
                id="lastname"
                onChange={(e) => setLastName(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-4 group">
              <label
                for="phone"
                className="block mb-2 text-sm font-medium text-gray-900"
              >   {t("Telefon raqami")}
                <code className="text-red-600">*</code>
              </label>
              <InputMask
                id="phone"
                onChange={(e) => setPhone(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
                mask={"+(999) 99 999 99 99"}
                placeholder="+(998) __ ___ __ __"
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >{t("Elektron pochta")}
                <code className="text-red-600">*</code>
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-4 group">
              <label
                for="phone"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("Tug'ilgan kuni")}
                <code className="text-red-600">*</code>
              </label>

              <input
                type="date"
                id="phone"
                onChange={(e) => setBthDate(e.target.value)}
                placeholder="dd/mm/yyyy"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("PINFL")}
                <code className="text-red-600">*</code>{" "}
                <em className="text-red-600">{validPnfl}</em>
              </label>

              <InputMask
                id="phone"
                onChange={(e) => setPnfl(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
                mask={"99999999999999"}
                placeholder="______________"
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-4 group">
              <label
                for="university"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("Bitirgan Oliy ta'lim muassasasi nomi")}
              </label>
              <input
                onChange={(e) => setUniversity(e.target.value)}
                type="text"
                id="university"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group ">
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 md:w-[146%] w-full mb-4 group ">
                  <label
                    for="direction"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("Ta'lim olgan yo`nalishi")}

                  </label>
                  <input
                    onChange={(e) => setTrainingDirection(e.target.value)}
                    type="text"
                    id="direction"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required
                  />
                </div>
                <div className="relative z-0 mb-5 group w-full md:w-[60%] md:ml-[80px] ">
                  <label
                    for="yearofback"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {t("Tamomlagan yili")}
                  </label>

                  <select
                    id="countries"
                    onChange={(e) => setYearGraduation(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected></option>
                    {rangeYear.map((year, id) => (
                      <option value={year} key={id}>
                        {year}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-4 group ">
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                for="user_avatar"
              >
                <div className="flex justify-between">
                  <p className="w-[120px]">

                    {t("Rezyume")} / CV <code className="text-red-600">*</code>
                  </p>
                  <p>
                    <em className="text-red-600">{filesize}</em> <br />
                    <em className="text-red-600">{fileformat}</em>
                  </p>
                </div>

              </label>

              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400"
                aria-describedby="user_avatar_help"
                id="user_avatar"
                accept="pdf/*"
                onChange={(event) => {
                  if (event.target.files && event.target.files[0]) {
                    if (event.target.files[0]['name'].slice(-4) !== '.pdf') {
                      setFileFormat("Faqat .pdf file qabul qilinadi")
                      return false
                    }
                    if (event.target.files[0]?.size > 2097152) {
                      setFileSize("2 MB dan ortiq qabul qilinmaydi")
                      return false
                    }
                    else {
                      setFile(event.target.files[0])
                      setFileSize("")
                      setFileFormat("")
                    }

                  }
                }}
                type="file"
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                for="workplace"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                {t("Hozirgi ish joyi")}
              </label>
              <input
                type="text"
                id="workplace"
                onChange={(e) => setPlaceWork(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                required
              />
            </div>
          </div>

          <div className="mb-5">
            <label
              for="workday"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {t("Doimiy yashash manzili")}

            </label>
            <input
              type="text"
              onChange={(e) => setAddress(e.target.value)}
              id="workday"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>

          <div className="mb-5">
            <label
              for="message"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {t("Ko'nikmalar")}

            </label>
            <textarea
              id="message"
              onChange={(e) => setSkills(e.target.value)}
              rows="3"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>
          <div className="mb-5">
            <label
              for="comment"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              {t("Izoh")}

            </label>
            <textarea
              id="comment"
              rows="3"
              onChange={(e) => setComment(e.target.value)}
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            ></textarea>
          </div>

          <div className="flex items-center my-7">
            <input
              id="checkbox"
              type="checkbox"
              onChange={(e) => setCheckbox(e.target.checked)}
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2  dark:border-gray-600"
            />
            <label
              for="checkbox"
              className="ms-2 text-sm font-medium text-gray-900"
            >
              {t("Men shaxsiy ma'lumotlarimni to'plash, qayta ishlash, saqlash va foydalanishga roziman. Ushbu rozilik cheksiz muddatga amal qiladi.")}
            </label>
          </div>

          <div className="flex justify-between mt-[280px]">
            <Link
              to={"/"}
              type="submit"
              className={`block md:w-[25%]  rounded-md bg-blue-800 px-3.5 py-2.5 text-center md:text-[16px] text-[14px] font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              {t("Orqaga")}

            </Link>

            {success ? (
              <button
                type="submit"
                className={`block md:w-[25%]  rounded-md bg-blue-800 px-3.5 py-2.5 text-center md:text-[16px] text-[14px] font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                {t("Yuborish")}
              </button>
            ) : (
              <button
                disabled
                className={`block md:w-[25%] cursor-not-allowed  rounded-md bg-blue-800 px-3.5 py-2.5 text-center md:text-[16px] text-[14px] font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                {t("Yuborish")}
              </button>
            )}
          </div>
        </form>

        <Captcha success={success} setSuccess={setSuccess} />
      </div>
    </div>
  );
};

export default Form;
